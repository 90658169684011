import { AppRoutes } from "./routes/AppRoutes";
import { useLayoutDirection } from "./layout/LayoutDireactionContext";
// import useWebSocket from 'react-use-websocket';

function App() {
  const { isRTL } = useLayoutDirection();
  return (
    <div className={isRTL ? "rtl" : "ltr"}>
      <AppRoutes />
    </div>
  );
}

export default App;
