import { toAbsoluteUrl } from "../../../../helpers";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
// import clsx from "clsx";
import { useNavigate, useLocation } from "react-router-dom";
import { DataService } from "../../../../dataservice/dataService";
import * as Constants from "../../../../config/constants";
import FeedBackModal from "../../../../utility/components/FeedBackModal";
import OTPInput from "../../../../utility/components/Otp";
import { LogoComponent, Contanier } from "./VerifyStyle";
import { getTranslation } from "../../../../utility/Translation";
import { useLayoutDirection } from "../../../../layout/LayoutDireactionContext";

const initialValues = {
  otp: "",
};
interface State {
  mobileNo: any;
  referenceId: string;
}
export function OtpVerify() {
  const { isRTL } = useLayoutDirection();
  const loginSchema = Yup.object().shape({
    otp: Yup.number().required(getTranslation("otp_required", isRTL)),
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [timer, setTimer] = useState(60);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [otpValue, setOtpValue] = useState("");

  const handleOtpChange = (value: any) => {
    setOtpValue(value);
    initialValues["otp"] = value;
  };
  const closeError = () => {
    setError(false);
  };
  console.log("clos", otpValue, initialValues);
  const location = useLocation();
  const properties = location.state as State;
  const mobileNo = parseInt(properties.mobileNo);
  const referenceId = properties.referenceId;

  const history = useNavigate();
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      let data = {
        mobileNo: mobileNo,
        otpNumber: parseInt(values.otp),
      };

      const endPoint = `user?${Constants.API_KEY}`;
      await DataService.post(endPoint, data)
        .then((result: { data: any }) => {
          history("/payment/summary", {
            state: { referenceId: referenceId, mobileNo: mobileNo },
          });
          // history('/auth/verify', { state: { mobileNo:values.mobileNo } })
          setLoading(false);
        })
        .catch((err: any) => {
          // console.log(err.response.data.error);
          setError(true);
          setErrorMessage(getTranslation(err.response.data.error, isRTL));
          setStatus(getTranslation("login_details_incorrect", isRTL));
          setSubmitting(false);
          setLoading(false);
        });
    },
  });
  useEffect(() => {
    let intervalId: any;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setButtonDisabled(true);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [timer]);
  return (
    <>
      {error && (
        <FeedBackModal
          openModal={error}
          closeModal={closeError}
          text={errorMessage}
          subText={getTranslation("please_try_again", isRTL)}
          type={"danger"}
        />
      )}
      <form
        className="form"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        <Contanier>
          <LogoComponent>
            {/* begin::Logo */}
            <span className="mb-5">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/tkrm.svg")}
                className="theme-dark-show h-45px"
              />
            </span>
          </LogoComponent>
          <div className="fv-row mb-10">
            <h1
              style={{
                fontWeight: 700,
                fontSize: "22px",
                lineHeight: "28px",
                marginBottom: "5px",
              }}
            >
              {getTranslation("verification", isRTL)}
            </h1>
            <h1
              style={{ fontWeight: 400, fontSize: "17px", lineHeight: "22px" }}
            >
              {getTranslation("please_enter_verification_code", isRTL)}
            </h1>
          </div>
          <div
            className="fv-row mb-10"
            style={{ alignItems: "center", textAlign: "center" }}
          >
            <label
              style={{
                fontWeight: 500,
                fontSize: "17px",
                lineHeight: "22px",
                display: "block",
                marginBottom: "5px",
              }}
            >
              {getTranslation("code", isRTL)}
            </label>

            <OTPInput onOtpChange={handleOtpChange} />

            {/* <input
              placeholder="otp"
              {...formik.getFieldProps("otp")}
              style={{
                background: "#FFFFFF",
                borderRadius: "10px",
                width: "30%",
                margin: "auto",
                padding: "0.75rem 1rem",
                border: "1px solid #ccc",
                // borderRadius: "0.25rem",
                fontSize: "1rem",
                backgroundColor: "#fff",
                color: "#333",
              }}
              className={clsx(
                "form-control form-control-lg form-control-solid",
                { "is-invalid": formik.touched.otp && formik.errors.otp },
                {
                  "is-valid": formik.touched.otp && !formik.errors.otp,
                }
              )}
              type="text"
              name="otp"
              autoComplete="off"
            /> */}
            {formik.errors.otp && (
              <div className="fv-plugins-message-container p-3">
                <span className="text-danger">{formik.errors.otp}</span>
              </div>
            )}
          </div>

          <div className="text-center">
            <button
              type="submit"
              id="kt_sign_in_submit"
              style={{ width: "100%" }}
              className="bg-black rounded text-white p-3"
              // disabled={formik.isSubmitting || !formik.isValid}
              disabled={buttonDisabled}
            >
              {!loading && (
                <span className="indicator-label">
                  {buttonDisabled ? (
                    <span style={{ color: "rgba(255, 255, 255, 0.4)" }}>
                      {getTranslation("verify", isRTL)}
                    </span>
                  ) : (
                    getTranslation("verify", isRTL)
                  )}
                </span>
              )}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {getTranslation("please_wait", isRTL)} ...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
          <div className="text-center">
            <p
              className="fw-bold text-grey fs-6 mb-0 p-3"
              style={{ fontWeight: 500 }}
            >
              {timer} {getTranslation("seconds_remian", isRTL)}
            </p>
          </div>
        </Contanier>
      </form>
    </>
  );
}
