/* eslint-disable @typescript-eslint/no-unused-vars */
import { toAbsoluteUrl } from "../../../../helpers";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import { Loading } from "../../../../helpers/Loading";
import * as Yup from "yup";
import clsx from "clsx";
import { DataService } from "../../../../dataservice/dataService";
import * as Constants from "../../../../config/constants";
import FeedBackModal from "../../../../utility/components/FeedBackModal";
import { useLayoutDirection } from "../../../../layout/LayoutDireactionContext";
import { Badge, LogoComponent, SelectComponent, Contanier } from "./LoginStyle";
import { getTranslation } from "../../../../utility/Translation";

const initialValues = {
  mobileNo: "",
  code: "966",
};

interface User {
  mobileNo: any;
  code?: any;
}
export function Payment() {
  const { isRTL, toggleLayoutDirection } = useLayoutDirection();

  const loginSchema = Yup.object().shape({
    mobileNo: Yup.number().required(
      getTranslation("mobile_number_required", isRTL)
    ),
    code: Yup.string().required(getTranslation("country_code_required", isRTL)),
  });
  // const WS_URL = 'ws://localhost:7000';
  const location = useLocation();
  const id = location.pathname.split("payment/user/")[1];
  const [loading, setLoading] = useState(false);
  const [screen, setScreen] = useState(false);
  const [error, setError] = useState<boolean>(false);
  const history = useNavigate();
  const [state, setState] = useState({
    code: [
      { label: "966", value: "966" },
      { label: "965", value: "965" },
      { label: "971", value: "971" },
      { label: "974", value: "974" },
      { label: "968", value: "968" },
      { label: "973", value: "973" },
    ],
  });

  const closeError = () => {
    setError(false);
  };
  const validation = (event: any) => {
    const isValidKey = /^[0-9]$/.test(event.key);
    const isControlKey =
      event.key === "Backspace" ||
      event.key === "Delete" ||
      event.key === "Tab";

    if (!isValidKey && !isControlKey) {
      event.preventDefault();
    }
  };
  // const apiKey:any = process.env.REACT_APP_WS_API_KEY;
  // const headers = ['WS_API_KEY', apiKey ,'deviceAccess' , 'access1234','63F3308BA0F1B214816B067C','63F330C9A0F1B214816B0681','64265CA776D2564190F19750','642662D3C93AE6165AB0498F', 'scanner'];
  // // const headers = [['WS_API_KEY', apiKey],['Authorization', 'userName']];
  // // // const headersArray = headers.map(header => [`${header[0]}`, `${header[1]}`]);
  // // const headersArray = headers.map(header => `${header[0]}: ${header[1]}`);
  // // console.log('headers',headersArray);
  // // const ws = new WebSocket(link,headersArray);
  // useWebSocket(WS_URL, {
  //   onOpen: () => {
  //     console.log('WebSocket connection established.');
  //   }
  // });

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      try {
        let data: User = {
          mobileNo: parseInt(values.code + values.mobileNo),
        };
        const loginEndpoint = `user/add?${Constants.API_KEY}`;
        DataService.post(loginEndpoint, data).then((result: { data: any }) => {
          // console.log('res', result);
          history("/payment/verify", {
            state: {
              mobileNo: parseInt(values.code + values.mobileNo),
              referenceId: id,
            },
          });
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
        setStatus("The login detail is incorrect");
        setSubmitting(false);
        setLoading(false);
      }
    },
  });
  const fetchData = async () => {
    try {
      if (!id) {
        setScreen(true);
        setError(true);
      } else {
        const activeCheckEndpoint = `group/${id}/validate?${Constants.API_KEY}`;
        await DataService.get(activeCheckEndpoint).then(
          async (result: { data: any }) => {
            // if the device is active hours of group
            console.log("Check", result.data.data.isInActiveHours);
            if (result.data.data.isInActiveHours) {
              const endpoint = `static/?${Constants.API_KEY}`;
              await DataService.get(endpoint).then((result: { data: any }) => {
                // console.log("res", result.data.data[0]);
                if (result.data.data[0].settings === false) {
                  setScreen(true);
                  if (!id) {
                    setError(true);
                  }
                } else {
                  setScreen(false);
                  history("/payment/summary", { state: { referenceId: id } });
                }
              });
            } else {
              window.location.href = "https://web.tkrm.co/store";
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  // let link = "wss://dev.tkrm.co";
  // if (process.env.REACT_APP_ENV === "test") {
  //   link =
  //     "ws://localhost:7000/?device_id=AA::BB::CC::DD::E&device_secret=aPdSgVkYp3s6v8y/";
  // } else if (process.env.REACT_APP_ENV === "development") {
  //   link =
  //     "wss://dev.tkrm.co/?device_id=AA::BB::CC::DD::E&device_secret=aPdSgVkYp3s6v8y/";
  // }
  // const ws = new WebSocket(link);
  // const hitWsApi = () => {
  //   ws.addEventListener("error", (event) => {
  //     console.log("WebSocket error:", event);
  //   });

  //   const data = { device_id: "AA::BB::CC::DD::E", message_type: "CONNECT" };
  //   ws.send(JSON.stringify(data));
  // };
  // const hitWsApi1 = () => {
  //   const data = { available_locks: ["user2"], message_type: "GROUPAVLBLRESP" };
  //   ws.send(JSON.stringify(data));
  // };
  // const hitWsApi2 = () => {
  //   const data = {
  //     device_id: "user2",
  //     availability: "FREE",
  //     message_type: "DEVICEAVLBLRESP",
  //   };
  //   ws.send(JSON.stringify(data));
  // };
  // ws.addEventListener("message", (event) => {
  //   console.log("Message from server:", event.data);
  // });
  useEffect(() => {
    const fetchApiResponse = async () => {
      await fetchData();
    };
    fetchApiResponse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return screen === true ? (
    <>
      {error && (
        <FeedBackModal
          openModal={error}
          closeModal={closeError}
          text={getTranslation("redirection_failed", isRTL)}
          subText={""}
          type={"danger"}
        />
      )}
      <form
        className="form"
        onSubmit={formik.handleSubmit}
        noValidate
        id="kt_login_signin_form"
      >
        <Contanier>
          <LogoComponent>
            {/* begin::Logo */}
            <span className="mb-5">
              <img
                alt="Logo"
                src={toAbsoluteUrl("/media/logos/tkrm.svg")}
                className="theme-dark-show h-45px"
              />
            </span>
          </LogoComponent>

          <div className="fv-row mb-10">
            <h1
              style={{ fontWeight: 700, fontSize: "22px", lineHeight: "28px" }}
            >
              {getTranslation("login", isRTL)}
            </h1>
            <h1
              style={{ fontWeight: 400, fontSize: "17px", lineHeight: "22px" }}
            >
              {getTranslation("please_enter_mobile_no", isRTL)}
            </h1>
          </div>

          <div className="fv-row mb-10">
            <label
              style={{
                fontWeight: 700,
                fontSize: "17px",
                lineHeight: "22px",
              }}
            >
              {getTranslation("mobile_no", isRTL)}
            </label>
            <div style={{ display: "flex", marginTop: "5px" }}>
              <SelectComponent isRTL={isRTL} {...formik.getFieldProps("code")}>
                {state.code.map((role, index) => {
                  return (
                    <option value={role.value} key={index}>
                      {role.label}
                    </option>
                  );
                })}
              </SelectComponent>

              {/* <select
                // className="form-control form-control-xl form-control-solid"
                style={{
                  marginRight: "10px",
                  marginLeft: "10px",
                  border: "1px solid #f5f8fa",
                  padding: "10px",
                  fontSize: "16px",
                  borderRadius: "5px",
                  backgroundColor: "#f5f8fa",
                  borderColor: "#f5f8fa",
                }}
                
              > */}
              {/* <option value="966" selected>
                  966
                </option> */}
              {/* {state.code.map((role, index) => {
                  return (
                    <option value={role.value} key={index}>
                      {role.label}
                    </option>
                  );
                })} */}
              {/* </select> */}
              <input
                placeholder={getTranslation("mobile_no", isRTL)}
                {...formik.getFieldProps("mobileNo")}
                maxLength={9}
                onKeyDown={(event) => {
                  validation(event);
                }}
                style={{
                  border: "1px solid #f5f8fa",
                  width: "70%",
                  padding: "10px",
                  fontSize: "16px",
                  borderRadius: "5px",
                  backgroundColor: "#f5f8fa",
                  borderColor: "#f5f8fa",
                }}
                className={clsx(
                  // "form-control form-control-lg form-control-solid",
                  {
                    "is-invalid":
                      formik.touched.mobileNo && formik.errors.mobileNo,
                  },
                  {
                    "is-valid":
                      formik.touched.mobileNo && !formik.errors.mobileNo,
                  }
                )}
                type="text"
                name="mobileNo"
                autoComplete="off"
              />
            </div>
            {formik.touched.mobileNo && formik.errors.mobileNo && (
              <div className="fv-plugins-message-container">
                <span className="text-danger">{formik.errors.mobileNo}</span>
              </div>
            )}
            {formik.touched.code && formik.errors.code && (
              <div className="fv-plugins-message-container">
                <span className="text-danger">{formik.errors.code}</span>
              </div>
            )}
          </div>

          <div className="text-center">
            <button
              type="submit"
              id="kt_sign_in_submit"
              style={{ width: "100%" }}
              className="bg-black rounded text-white p-3"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && (
                <span>{getTranslation("send_verification_code", isRTL)}</span>
              )}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: "block" }}
                >
                  {getTranslation("please_wait", isRTL)} ...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
          <br />
          <div className="fv-row mb-10">
            <label
              style={{ fontWeight: 700, fontSize: "17px", lineHeight: "22px" }}
            >
              {getTranslation("language", isRTL)}

              {/* {getTranslation('language', languageDirection)} */}
            </label>

            <div className="py-5">
              <Badge
                isRTL={isRTL === true ? false : true}
                onClick={() => {
                  toggleLayoutDirection(false);
                }}
              >
                {getTranslation("english", isRTL)}
              </Badge>

              <Badge
                isRTL={isRTL === false ? false : true}
                onClick={() => {
                  toggleLayoutDirection(true);
                }}
              >
                {getTranslation("arabic", isRTL)}
              </Badge>
            </div>
          </div>
        </Contanier>
      </form>
      {/* <button className="bg-black rounded text-white p-3" onClick={hitWsApi}>
        conncecting message
      </button>
      <button className="bg-black rounded text-white p-3" onClick={hitWsApi1}>
        Group avaliblitity
      </button>
      <button className="bg-black rounded text-white p-3" onClick={hitWsApi2}>
        Device Occupied query
      </button> */}
    </>
  ) : (
    <Loading />
  );
}
