/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { Payment } from "./components/login/Payment";
import { OtpVerify } from "./components/verify/Verify";
import { CheckOut } from "./components/checkout/CheckOut";
import { OrderSumary } from "./components/order/OrderSumary";
import { Result } from "./components/result/Result";
import { QrDisplay } from "./components/qrDisplay/QrDisplay";
import styled from "styled-components";

const MyStyledComponent = styled.div`
  width: 500px;
  background-color: var(--bg-body);
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  margin: auto;

  @media (max-width: 767px) {
    /* Styles for small screens (sm) */
    width: 100%; /* Adjust as needed */
  }

  @media (min-width: 768px) and (max-width: 991px) {
    /* Styles for medium screens (md) */
    width: 430px; /* Adjust as needed */
  }

  @media (min-width: 992px) {
    /* Styles for large screens (lg) */
    width: 430px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = "none";
    return () => {};
  }, []);

  return (
    <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed">
      <div className="d-flex flex-center flex-column flex-column-fluid">
        <MyStyledComponent>
          <Outlet />
        </MyStyledComponent>
      </div>
    </div>
  );
};

const PaymentPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path="user/*" element={<Payment />} />
      <Route path="verify" element={<OtpVerify />} />
      <Route path="summary" element={<OrderSumary />} />
      <Route path="checkout" element={<CheckOut />} />
      <Route path="result/*" element={<Result />} />
      <Route path="display" element={<QrDisplay />} />
      <Route index element={<Payment />} />
    </Route>
  </Routes>
);

export { PaymentPage };
