import React, {
  useState,
  useRef,
  ChangeEvent,
  KeyboardEvent,
  useEffect,
} from "react";
import styled from "styled-components";

const OTPInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%; /* Adjust the width as needed */
  margin: auto;
  direction: ltr; /* Force left-to-right direction */
`;

const OTPInputBox = styled.input`
  width: 2rem; /* Adjust the width to hold only one number */
  text-align: center;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 1.3rem;
  background-color: #f5f8fa;
  inputmode: numeric;
  -moz-appearance: textfield;
`;

interface OTPInputProps {
  onOtpChange: (otpValue: string) => void;
}

const OTPInput: React.FC<OTPInputProps> = ({ onOtpChange }) => {
  const [otp, setOTP] = useState(["", "", "", ""]);
  const otpInputs = useRef<Array<HTMLInputElement | null>>([
    null,
    null,
    null,
    null,
  ]);

  useEffect(() => {
    // Focus on the first input box when the component is initially rendered
    if (otpInputs.current[0]) {
      otpInputs.current[0].focus();
    }
  }, []);

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { value } = event.target;

    // Ensure the input is a single digit and numeric
    if (/^[0-9]$/.test(value)) {
      const newOTP = [...otp];
      newOTP[index] = value;
      setOTP(newOTP);

      // Move focus to the next input field if available
      if (index < 3 && otpInputs.current[index + 1]) {
        otpInputs.current[index + 1]?.focus();
      } else if (index === 3 && value) {
        otpInputs.current[index]?.blur();
        otpInputs.current[index + 1]?.focus();
      }

      // Call the callback to update the parent's OTP value
      onOtpChange(newOTP.join(""));
    }
  };

  const handleKeyDown = (
    event: KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.key === "Backspace") {
      // Handle backspace key to move to the previous input and clear the value
      if (otp[index] === "") {
        otpInputs.current[index - 1]?.focus();
      }
      const newOTP = [...otp];
      newOTP[index] = "";
      setOTP(newOTP);

      // If in the first input box and Backspace is pressed, focus on the input
      // This ensures you can keep pressing Backspace to clear the previous input

      // Call the callback to update the parent's OTP value
      onOtpChange(newOTP.join(""));
    }
  };

  return (
    <OTPInputContainer>
      {otp.map((digit, index) => (
        <OTPInputBox
          key={index}
          ref={(input) => (otpInputs.current[index] = input)}
          type="text"
          value={digit}
          maxLength={1}
          onChange={(event) => handleInputChange(event, index)}
          onKeyDown={(event) => handleKeyDown(event, index)}
          name={`otp[${index}]`} // Set the input name using the index
          inputMode="numeric"
        />
      ))}
    </OTPInputContainer>
  );
};

export default OTPInput;
