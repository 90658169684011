/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import queryString from "query-string";
import { DataService } from "../../../../dataservice/dataService";
import { useNavigate, useLocation } from "react-router-dom";
import * as Constants from "../../../../config/constants";
import { toAbsoluteUrl } from "../../../../helpers";
import { Loading } from "../../../../helpers/Loading";
import { setItem } from "../../../../utility/localstoragecontrol";
import FeedBackModal from "../../../../utility/components/FeedBackModal";
import { LogoComponent, Contanier } from "./ResultStyle";
import { getTranslation } from "../../../../utility/Translation";
import { useLayoutDirection } from "../../../../layout/LayoutDireactionContext";
export function Result() {
  const { isRTL } = useLayoutDirection();

  const location = useLocation();
  const history = useNavigate();
  const orderId = location.pathname
    .split("payment/result/")[1]
    .split("/&type=")[0];
  const type = new URLSearchParams(location.pathname).get("type");
  // console.log("Location", location, type);
  const [state, setState] = useState({
    responseData: null,
    loading: true,
  });
  const [error, setError] = useState(false);
  const [reference, setReference] = useState("");
  const fetchData = async () => {
    const parsed = queryString.parse(location.search);
    const resourcePath = parsed.resourcePath;
    const loginEndpoint = `payment/result?${Constants.API_KEY}`;
    let data = {
      resourcePath: resourcePath,
      orderId: orderId,
      type: type,
    };
    await DataService.post(loginEndpoint, data)
      .then(async (result: { data: any }) => {
        setState({
          ...state,
          responseData: result.data.data,
        });
        // console.log("result.data.data");
        await checkResult(result.data.data, result.data.orderData.groupId);
      })
      .catch((error: any) => {
        console.log("error", error, error.response.data);
        error.response.data.message && setError(() => true);
        window.history.back();
      });
  };
  useEffect(() => {
    const fetchApiResponse = async () => {
      await fetchData();
    };
    fetchApiResponse();
  }, []);
  const call = async (boolean: boolean, status: string, id: any) => {
    // console.log({ boolean });

    const endpoint = `transaction/${orderId}?${Constants.API_KEY}`;
    let data = {
      status: status,
      paid: boolean,
    };
    await DataService.post(endpoint, data)
      .then((result: { data: any }) => {
        history("/payment/display", {
          state: {
            boolean: boolean,
            result: result.data.data,
            status: status,
            orderId: orderId,
            reference: id, //Group Id
          },
        });
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  };

  const closeError = () => {
    // console.log("trying to close error");
    history("/payment/summary", {
      state: {
        referenceId: reference,
        // checkoutId1: checkoutId1,
        // checkoutId2: checkoutId2,
        // orderId: orderId1,
      },
    });
  };

  const checkResult = async (status: any, id: any) => {
    // const successPattern = /^(000\.000\.|000\.100\.1|000\.[36])/;
    // const manuallPattern = /^(000\.400\.0[^3]|000\.400\.100)/;
    // let match1;
    // let match2;
    // if (processId !== null) {
    //   match1 = successPattern.test(processId);
    //   match2 = manuallPattern.test(processId);
    // }
    if (status === Constants.PAYMENTSTATUSTRUE) {
      // console.log({ status });

      await call(true, Constants.PAYMENT_SUCCESS, id);
      setItem("revisted", "yes");
      setItem("payment", "unsucess");
    } else {
      // console.log({ status });

      setReference(id);
      setItem("revisted", "yes");
      setItem("payment", "unsucess");
      await call(false, Constants.PAYMENT_FAILED, id);
    }
  };

  return (
    <Contanier>
      <LogoComponent>
        <span className="mb-5">
          <img
            alt="Logo"
            src={toAbsoluteUrl("/media/logos/tkrm.svg")}
            className="theme-dark-show h-45px"
          />
        </span>
      </LogoComponent>
      {error && (
        <FeedBackModal
          openModal={error}
          closeModal={closeError}
          text={getTranslation("session_timedOut", isRTL)}
          subText={getTranslation("please_try_again", isRTL)}
          type={"danger"}
        />
      )}
      {!state.loading ? (
        // <>
        //   {qrCode === true ? (
        //     <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        //       <img className="w-full" src={data} alt="qr"></img>

        //       <div className="px-6 py-4">
        //         <div
        //           className="font-bold text-xl mb-2"
        //           style={{ textAlign: "center" }}
        //         >
        //           Order Placed
        //         </div>
        //         <div style={{ textAlign: "center" }}>Thanks for Your Order</div>
        //         <div className="px-3 py-2" style={{ textAlign: "center" }}>
        //           <button
        //             className="bg-black rounded text-white p-3"
        //             onClick={() => onDownload(data, "Qr image")}
        //           >
        //             Download
        //           </button>
        //         </div>
        //       </div>
        //     </div>
        //   ) : (
        //     <div className="overflow-hidden bg-white shadow sm:rounded-lg">
        //       <img
        //         className="w-full"
        //         src={toAbsoluteUrl("/media/image/payF.png")}
        //         alt="qrF"
        //       ></img>
        //       <div className="px-6 py-4">
        //         <div
        //           className="font-bold text-xl mb-2"
        //           style={{ textAlign: "center" }}
        //         >
        //           Order Placed but Payment Failed
        //         </div>
        //         <div style={{ textAlign: "center" }}>
        //           Thanks for Your Order, please try again the payment
        //         </div>
        //         <div className="px-3 py-2" style={{ textAlign: "center" }}>
        //           <button
        //             className="bg-black rounded text-white p-3"
        //             onClick={onSubmit}
        //           >
        //             Try again payment
        //           </button>
        //         </div>
        //       </div>
        //     </div>
        //   )}
        // </>
        <h1> {getTranslation("session_timedOut", isRTL)}</h1>
      ) : (
        <>
          <Loading />
        </>
      )}
    </Contanier>
  );
}
