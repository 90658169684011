import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { setItem } from "../../../../utility/localstoragecontrol";
import * as Constants from "../../../../config/constants";
import { DataService } from "../../../../dataservice/dataService";
import { toAbsoluteUrl } from "../../../../helpers";
import { Loading } from "../../../../helpers/Loading";
import FeedBackModal from "../../../../utility/components/FeedBackModal";
import { LogoComponent, Contanier } from "./QrStlye";
import { getTranslation } from "../../../../utility/Translation";
import { useLayoutDirection } from "../../../../layout/LayoutDireactionContext";
interface State {
  boolean: boolean;
  status: any;
  orderId: any;
  reference: any;
  result: any;
}

export function QrDisplay() {
  const { isRTL } = useLayoutDirection();
  const [data, setData] = useState();
  const [qrCode, setQrCode] = useState(false);
  const [regenerateButtonShow, setRegenerateButtonShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const location = useLocation();
  const history = useNavigate();
  const state = location.state as State;
  const reference = state ? state.reference : null;
  const boolean = state ? state.boolean : null;
  //   const status = state ? state.status : null;
  const orderId = state ? state.orderId : null;
  const result = state ? state.result : null;
  const closeError = () => {
    // console.log("trying to close error");
    setError(false);
  };
  const imageStyle = {
    width: "100%",
    filter: regenerateButtonShow ? "blur(5px)" : "none", // Adjust the blur intensity as needed
  };
  useEffect(() => {
    console.log({ boolean });
    const fetchDetails = async () => {
      if (boolean !== null) {
        if (boolean === true) {
          //fetch Order details here
          const endpoint = `order/expiryOrder/${orderId}?${Constants.API_KEY}`;
          await DataService.get(endpoint)
            .then((doc: { data: any }) => {
              console.log("Order summary", doc);
              if (doc.data.data.orderExpiry) {
                //Qr is expired and show regenerate button
                setRegenerateButtonShow(false);
              }
              setQrCode(true);
              setData(doc.data.data.qrImage);
            })
            .catch((error: any) => {
              console.log("error", error);
              setQrCode(false);
            });
        } else {
          setQrCode(false);
        }
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    };
    fetchDetails();
  }, [boolean, result]);

  // previous function -for the base64Image
  // function downloadImage(base64Image: string, fileName: string) {
  //   // Convert base64 to Blob
  //   const byteString = atob(base64Image.split(",")[1]);
  //   const mimeString = base64Image
  //     .split(",")[0]
  //     .split(":")[1]
  //     .split(";")[0];
  //   const arrayBuffer = new ArrayBuffer(byteString.length);
  //   const intArray = new Uint8Array(arrayBuffer);
  //   for (let i = 0; i < byteString.length; i++) {
  //     intArray[i] = byteString.charCodeAt(i);
  //   }
  //   const blob = new Blob([arrayBuffer], { type: mimeString });

  //   // Create a URL for the Blob
  //   const url = URL.createObjectURL(blob);

  //   // Create a link and simulate a click on it
  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = fileName;
  //   link.click();

  //   // Cleanup the URL object
  //   URL.revokeObjectURL(url);
  // }

  //testing phase

  async function downloadImage(imageUrl: string, fileName: string) {
    try {
      // Fetch the image data
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      // Create a temporary link
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;

      // Simulate a click on the link to trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up: remove the link from the DOM
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  }
  const onDownload: any = (str: string, text: string) => {
    console.log("image", str);
    downloadImage(str, text);
  };
  const onSubmit = () => {
    const fetchApiResponse = async () => {
      await sendData();
    };
    fetchApiResponse();
  };
  const regenerateOrder = async () => {
    setLoading(true);

    const endpoint = `websocket/regenerate/${orderId}?${Constants.API_KEY}`;
    await DataService.get(endpoint)
      .then((result: { data: any }) => {
        setData(result.data.data);
        setLoading(false);
        window.location.reload();
      })
      .catch((error: any) => {
        console.log("error", error);
        setLoading(false);
      });
  };
  const sendData = async () => {
    const loginEndpoint = `payment/checkout?${Constants.API_KEY}`;
    let data = {
      referenceId: reference,
      created: Constants.ORDERCREATEDFALSE,
      orderId: orderId,
    };
    // console.log("refee", data);
    await DataService.post(loginEndpoint, data)
      .then((result: { data: any }) => {
        // console.log("Order summary", result);
        setItem("payment", "sucess");
        let checkoutId1 = result.data.data.id;
        let checkoutId2 = result.data.mada.id;
        let orderId1 = result.data.orderId;
        history("/payment/checkout", {
          state: {
            referenceId: reference,
            checkoutId1: checkoutId1,
            checkoutId2: checkoutId2,
            orderId: orderId1,
          },
        });
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <Contanier>
        <LogoComponent>
          <span className="mb-5">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/tkrm.svg")}
              className="theme-dark-show h-45px"
            />
          </span>
        </LogoComponent>
        {error && (
          <FeedBackModal
            openModal={error}
            closeModal={closeError}
            text={getTranslation("payment_or_order_not_done", isRTL)}
            subText={getTranslation("please_try_order", isRTL)}
            type={"danger"}
          />
        )}

        {!loading ? (
          <>
            {qrCode === true && boolean !== null ? (
              <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                <img style={imageStyle} src={data} alt="qr"></img>

                <div className="px-6 py-4">
                  <div
                    className="font-bold text-xl mb-2"
                    style={{ textAlign: "center" }}
                  >
                    {getTranslation("display_scanner", isRTL)}
                  </div>
                  {/* <div style={{ textAlign: "center" }}>
                    Thanks for Your Order
                  </div> */}
                  {regenerateButtonShow ? (
                    <div className="px-3 py-2" style={{ textAlign: "center" }}>
                      <button
                        className="bg-black rounded text-white p-2"
                        onClick={regenerateOrder}
                      >
                        {getTranslation("Regenerate QR", isRTL)}
                      </button>
                    </div>
                  ) : (
                    <div className="px-3 py-2" style={{ textAlign: "center" }}>
                      <button
                        className="bg-black rounded text-white p-2"
                        onClick={() => onDownload(data, "Qr image")}
                      >
                        {getTranslation("download", isRTL)}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ) : qrCode === false && boolean !== null ? (
              <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                <img
                  className="w-full"
                  src={toAbsoluteUrl("/media/image/payF.png")}
                  alt="qrF"
                ></img>
                <div className="px-6 py-4">
                  <div
                    className="font-bold text-xl mb-2"
                    style={{ textAlign: "center" }}
                  >
                    {getTranslation("order_placed_payment_failed", isRTL)}
                  </div>
                  <div style={{ textAlign: "center" }}>
                    {getTranslation("thanks_for_order_please_try_again", isRTL)}
                  </div>
                  <div className="px-3 py-2" style={{ textAlign: "center" }}>
                    <button
                      className="bg-black rounded text-white p-3"
                      onClick={onSubmit}
                    >
                      {getTranslation("try_again_pay", isRTL)}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="overflow-hidden bg-white shadow sm:rounded-lg">
                <div className="px-6 py-4">
                  <div
                    className="font-bold text-xl mb-2"
                    style={{ textAlign: "center" }}
                  >
                    {getTranslation("order_not_places", isRTL)}
                  </div>
                  <div style={{ textAlign: "center" }}>
                    {getTranslation("place_order_first_then_pay", isRTL)}
                  </div>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            <Loading />
          </>
        )}
      </Contanier>
    </>
  );
}
