import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { DataService } from "../../../../dataservice/dataService";
import * as Constants from "../../../../config/constants";
import Config from "../../../../config/config";
import { Loading } from "../../../../helpers/Loading";
import { toAbsoluteUrl } from "../../../../helpers";
import { getItem } from "../../../../utility/localstoragecontrol";
import { getTranslation } from "../../../../utility/Translation";
import { useLayoutDirection } from "../../../../layout/LayoutDireactionContext";
// import "./style.css";
interface State {
  referenceId: string;
  mobileNo: any;
  checkoutId1: any;
  checkoutId2: any;
  checkoutId3: any;
  orderId: any;
}
export function CheckOut() {
  const { isRTL } = useLayoutDirection();

  // const history = useNavigate();
  const revisted = getItem("revisted");
  const payment = getItem("payment");
  // console.log("revisted", revisted, payment);
  if (revisted !== "no" && payment !== "sucess") {
    // console.log("called to go back");
    window.history.back();
    window.location.reload();
  }
  const [state, setState] = useState({
    checkoutId1: null,
    checkoutId2: null,
    checkoutId3: null,
    totalAmount: 0,
    paymentAmount: 0,
    loading: true,
  });
  const [payCard, setPayCard] = useState(false);
  const [payCardMada, setPayCardMada] = useState(false);
  const [payApple, setPayApple] = useState(false);
  const [defaultButtonloading, setDefaultButtonloading] = useState(true);
  const [visaButtonLoading, setVisaButtonLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const [madaButtonLoading, setMadaButtonLoading] = useState(false);
  // const [appleLoading, setAppleLoading] = useState(false);
  const [totalLoading, setTotalLoading] = useState(true);
  // const [groupData, setGroupData] = useState<Array<any>>([]);
  const location = useLocation();
  const properties = location.state as State;
  const checkoutId1 = properties.checkoutId1;
  const checkoutId2 = properties.checkoutId2;
  const checkoutId3 = properties.checkoutId3;
  const mobileNo = properties.mobileNo;

  // console.log("checkOut", mobileNo);
  const orderId = properties.orderId;
  const referenceId = properties.referenceId;
  // const history = useNavigate();
  function isIOSDevice() {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  }
  const deleteRegistration = async (type: any, index: any) => {
    const endPoint = `payment/delete?${Constants.API_KEY}`;
    let data = {
      mobileNo: mobileNo,
      type: type,
      indexToDelete: index,
    };
    await DataService.post(endPoint, data)
      .then((result: { data: any }) => {
        console.log("success");
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  };
  const fetchData = async () => {
    const endPoint = `group/${referenceId}/details?${Constants.API_KEY}`;
    try {
      await DataService.get(endPoint).then(async (result: { data: any }) => {
        const orderTableEndPoint = `order/orderTable/${orderId}?${Constants.API_KEY}`;
        await DataService.get(orderTableEndPoint).then((orderDetails) => {
          // console.log("orderDetails", orderDetails.data.data[0]);
          setState({
            checkoutId1: checkoutId1,
            checkoutId2: checkoutId2,
            checkoutId3: checkoutId3,
            totalAmount: Number(orderDetails.data.data[0]?.totalAmount),
            paymentAmount: Number(orderDetails.data.data[0]?.paymentAmount),
            loading: false,
          });
        });
        // console.log(
        //   "result",
        //   result.data.data[0]?.amount,
        //   result.data.paymentAmount
        // );
        // setGroupData(result.data.data);
      });
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchApiResponse = async () => {
      await fetchData();
      setTimeout(() => {
        // setPayApple(true);
        setTotalLoading(false);
      }, 2000);
      // setTimeout(() => {
      //   setState({
      //     checkoutId1: checkoutId1,
      //     checkoutId2: checkoutId2,
      //     loading: false,
      //   });
      //   renderPaymentform1(checkoutId1);
      //   renderPaymentform2(checkoutId2);
      // }, 2000);
    };
    fetchApiResponse();
    return () => {
      window.location.reload();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const generatePaymentLink = (orderId: any, paymentType: any) => {
    const baseUrl =
      process.env.REACT_APP_ENV === Constants.DEV
        ? Config.FRONTEND_DEV_URL
        : process.env.REACT_APP_ENV === Constants.PROD
        ? Config.FRONTEND_PROD_URL
        : Config.FRONTEND_LOCAL_URL;

    return `${baseUrl}payment/result/${orderId}/&type=${paymentType}`;
  };

  const wpwlOptions = {
    onReady: function(type: string) {
      const createRegistrationHtml =
        '<div class="customLabel"> Store payment details ?</div><div class="customInput"><input type="checkbox" name="createRegistration" value="true" /></div>';
      const form: any = document.querySelector("form.wpwl-form-card");
      const button = form.querySelector(".wpwl-button");
      button.insertAdjacentHTML("beforebegin", createRegistrationHtml);
      const registrationDivs = document.querySelectorAll(
        ".wpwl-group.wpwl-group-registration"
      );
      const submitButton = document.querySelector(".wpwl-form-registrations");

      registrationDivs.forEach((div, index) => {
        console.log("div", div);
        const customButton = document.createElement("span");
        const deleteIcon = "\u{1F5D1}";
        customButton.innerHTML = deleteIcon + getTranslation("delete", isRTL);
        customButton.classList.add("custom-button");
        customButton.addEventListener("click", () => {
          console.log("Custom button " + index + " clicked", type);
          if (div.parentNode) {
            div.parentNode.removeChild(div);
            deleteRegistration(type, index);
            if (
              document.querySelectorAll(".wpwl-group.wpwl-group-registration")
                .length === 0
            ) {
              (submitButton as HTMLButtonElement).style.display = "none";
            }
          }
        });
        customButton.style.padding = "8px";
        customButton.style.borderRadius = "5px";
        customButton.style.backgroundColor = "red";
        customButton.style.color = "white";
        customButton.style.margin = "10px";
        customButton.style.display = "inline-block";
        customButton.style.width = "90%";
        customButton.style.height = "50px";
        customButton.style.cursor = "pointer";
        div.appendChild(customButton);
      });
    },
  };
  // let link = `${Config.FRONTEND_LOCAL_URL}payment/result`

  // const renderPaymentform1 = (id: any) => {
  //   const wpwlOptionsData = {
  //     paymentTarget: "_top",
  //     locale: isRTL ? "ar" : "en",
  //     registrations: {
  //       requireCvv: false,
  //       hideInitialPaymentForms: true,
  //     },
  //   };
  //   const script1 = document.createElement("script");
  //   script1.textContent = `var wpwlOptions = ${JSON.stringify(
  //     wpwlOptionsData
  //   )};`;
  //   document.body.appendChild(script1);

  //   const script2 = document.createElement("script");
  //   script2.src = `https://${process.env.REACT_APP_PAYMENT_URL}/v1/paymentWidgets.js?checkoutId=${id}`;
  //   script2.async = true;
  //   document.body.appendChild(script2);
  //   const link = generatePaymentLink(orderId, Constants.VISA);

  //   setTimeout(() => {
  //     wpwlOptions.onReady(Constants.VISA);
  //   }, 2000);

  //   const styleTag = (
  //     <style>
  //       {`
  //           button, [type='button'], [type='reset'], [type='submit'] {
  //               background-color: #5cb85c;
  //           }
  //           `}
  //     </style>
  //   );

  //   return (
  //     <div>
  //       {styleTag}
  //       <form
  //         action={link}
  //         className="paymentWidgets"
  //         data-brands="VISA MASTER AMEX"
  //       ></form>
  //     </div>
  //   );
  // };

  // const renderPaymentform2 = (id: any) => {
  //   // console.log("Loading ", id);
  //   const wpwlOptionsData = {
  //     paymentTarget: "_top",
  //     locale: isRTL ? "ar" : "en",
  //     registrations: {
  //       requireCvv: true,
  //       hideInitialPaymentForms: true,
  //     },
  //   };
  //   const script1 = document.createElement("script");
  //   script1.textContent = `var wpwlOptions = ${JSON.stringify(
  //     wpwlOptionsData
  //   )};`;
  //   document.body.appendChild(script1);

  //   const script2 = document.createElement("script");
  //   script2.src = `https://${process.env.REACT_APP_PAYMENT_URL}/v1/paymentWidgets.js?checkoutId=${id}`;
  //   script2.async = true;
  //   document.body.appendChild(script2);
  //   const link = generatePaymentLink(orderId, Constants.MADA);

  //   const styleTag = (
  //     <style>
  //       {`

  //         button, [type='button'], [type='reset'], [type='submit'] {
  //           background-color: #5cb85c;
  //         }
  //       `}
  //     </style>
  //   );
  //   setTimeout(() => {
  //     wpwlOptions.onReady(Constants.MADA);
  //   }, 2000);
  //   return (
  //     <div>
  //       {/* <script
  //         dangerouslySetInnerHTML={{
  //           __html: `var wpwlOptions = ${JSON.stringify(wpwlOption)};`,
  //         }}
  //       ></script> */}
  //       {styleTag}
  //       <form
  //         action={link}
  //         className="paymentWidgets"
  //         data-brands="MADA"
  //       ></form>
  //     </div>
  //   );
  // };

  // const renderPaymentform3 = (id: any) => {
  //   const wpwlOptionsData = {
  //     paymentTarget: "_top",
  //     applePay: {
  //       displayName: "TKRM",
  //       total: { label: "TKRM" },
  //       supportedNetworks: ["masterCard", "visa", "mada"],
  //       supportedCountries: ["SA"],
  //     },
  //   };
  //   const script1 = document.createElement("script");
  //   script1.textContent = `var wpwlOptions = ${JSON.stringify(
  //     wpwlOptionsData
  //   )};`;
  //   document.body.appendChild(script1);

  //   const script2 = document.createElement("script");
  //   // console.log("cjec", id);
  //   script2.src = `https://${process.env.REACT_APP_PAYMENT_URL}/v1/paymentWidgets.js?checkoutId=${id}`;
  //   script2.async = true;
  //   document.body.appendChild(script2);
  //   const link = generatePaymentLink(orderId, Constants.APPLE);

  //   // Configure the options for Apple Pay

  //   // Apply styles
  //   // background-color: black !important;
  //   //     color: black !important;
  //   //     font-size: 58px;
  //   //     border-radius: 8px;
  //   //     padding: 12px 24px;
  //   const styleTag = (
  //     <style>
  //       {`.wpwl-apple-pay-button {
  //       -webkit-appearance: -apple-pay-button !important;
  //       -apple-pay-button-type: plain !important;
  //       -apple-pay-button-style: black !important;
  //       border-radius: 4px;
  //       padding: 18px 24px;
  //       margin-top:10px;
  //       width:50%;
  //     }

  //     `}
  //     </style>
  //   );

  //   return (
  //     <div>
  //       {/* <script
  //         dangerouslySetInnerHTML={{
  //           __html: `var wpwlOptions = ${JSON.stringify(wpwlOptionApple)};`,
  //         }}
  //       ></script> */}
  //       {styleTag}
  //       <form
  //         action={link}
  //         className="paymentWidgets"
  //         data-brands="APPLEPAY"
  //       ></form>
  //     </div>
  //   );
  // };

  const renderPaymentForm = (id: any, formType: string, brands: string) => {
    let wpwlOptionsData;
    let styleTag;
    if (formType === Constants.APPLE) {
      wpwlOptionsData = {
        paymentTarget: "_top",
        applePay: {
          displayName: "TKRM",
          total: { label: "TKRM" },
          supportedNetworks: ["masterCard", "visa", "mada"],
          supportedCountries: ["SA"],
        },
      };
      styleTag = (
        <style>
          {`.wpwl-apple-pay-button {
        -webkit-appearance: -apple-pay-button !important;
        -apple-pay-button-type: plain !important;
        -apple-pay-button-style: black !important;
        border-radius: 4px;
        padding: 18px 24px;
        margin-top:10px;
        width:50%;
      }
      
      `}
        </style>
      );
    } else {
      wpwlOptionsData = {
        paymentTarget: "_top",
        locale: isRTL ? "ar" : "en",
        registrations: {
          requireCvv: true,
          hideInitialPaymentForms: true,
        },
      };
      styleTag = (
        <style>
          {`
              button, [type='button'], [type='reset'], [type='submit'] {
                  background-color: #5cb85c;
              }
              `}
        </style>
      );
    }
    const script1 = document.createElement("script");
    script1.textContent = `var wpwlOptions = ${JSON.stringify(
      wpwlOptionsData
    )};`;
    document.body.appendChild(script1);
    const script2 = document.createElement("script");
    script2.src = `https://${process.env.REACT_APP_PAYMENT_URL}/v1/paymentWidgets.js?checkoutId=${id}`;
    script2.async = true;

    document.body.appendChild(script2);
    setTimeout(() => {
      wpwlOptions.onReady(formType);
    }, 2000);
    const link = generatePaymentLink(orderId, formType);
    return (
      <div>
        {styleTag}
        <form
          action={link}
          className="paymentWidgets"
          data-brands={brands}
        ></form>
      </div>
    );
  };

  const setAppleTimer = () => {
    setTimeout(() => {
      setDefaultButtonloading(false);
    }, 1500);
  };

  return (
    <div>
      {!state.loading && !totalLoading ? (
        <div
          className="d-flex flex-center flex-column flex-column-fluid pt-10 items-center "
          style={{ maxWidth: "500px", height: "980px" }}
        >
          <div className="pb-4" style={{ textAlign: "center" }}>
            <h1 className="text-sm font-bold">
              {getTranslation("payment", isRTL)}
            </h1>
          </div>

          {/* {renderPaymentform()} */}
          <div
            className="bg-gray-50 items-center"
            style={{ width: "100%", height: "100%" }}
          >
            <div className="pt-5">
              <div
                className="items-center overflow-hidden bg-white shadow sm:rounded-lg p-1"
                style={{ width: "280px", margin: "auto" }}
              >
                <div className=" px-3 py-2 grid grid-cols-2 sm:gap-6  sm:px-6">
                  <div
                    className="text-sm font-medium "
                    style={{ display: "inline-block" }}
                  >
                    {getTranslation("restroom_access", isRTL)}
                  </div>
                  <div
                    className="text-sm font-medium"
                    style={{ display: "inline-block", textAlign: "right" }}
                  >
                    {state.totalAmount.toFixed(2)}{" "}
                    {getTranslation("sar", isRTL)}
                  </div>
                </div>
                <div className=" px-3 pb-3 grid grid-cols-2 sm:gap-6 sm:px-6">
                  <div
                    className="text-sm font-medium "
                    style={{ display: "inline-block" }}
                  >
                    {getTranslation("vat_fifteen_percent", isRTL)}
                  </div>
                  <div
                    className="text-sm font-medium"
                    style={{ display: "inline-block", textAlign: "right" }}
                  >
                    <p style={{ display: "inline-block" }}>
                      {(
                        Number(state.paymentAmount.toFixed(2)) -
                        Number(state.totalAmount.toFixed(2))
                      ).toFixed(2)}
                    </p>{" "}
                    <span>{getTranslation("sar", isRTL)}</span>
                  </div>
                </div>
                <div
                  className="py-2 grid grid-cols-2 sm:gap-6 "
                  style={{
                    borderTop: "1px dashed black",
                    width: "90%",
                    margin: "auto",
                  }}
                >
                  <div
                    className="text-sm font-medium "
                    style={{ display: "inline-block" }}
                  >
                    {getTranslation("total", isRTL)}
                  </div>
                  <div
                    className="text-sm font-medium"
                    style={{ display: "inline-block", textAlign: "right" }}
                  >
                    {state.paymentAmount.toFixed(2)}{" "}
                    {getTranslation("sar", isRTL)}
                  </div>
                </div>
              </div>
            </div>
            <div style={{ width: "90%", margin: "auto" }} className="pt-5">
              <div
                className="text-sm font-bold"
                style={{ fontSize: "15px", padding: "0px 10px" }}
              >
                {getTranslation("payment_type", isRTL)}
              </div>
              <div
                style={{
                  width: "100%",
                  alignContent: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  margin: "auto",
                }}
              >
                <div
                  // className="items-center overflow-hidden bg-gray-50 p-1"
                  style={{ height: "95%", marginTop: "20px" }}
                >
                  {isIOSDevice() && (
                    <>
                      <div className="overflow-hidden bg-white shadow rounded-lg pt-4 pb-4 pl-2 pr-2">
                        {defaultButtonloading && (
                          <button
                            className="py-2 bg-black rounded text-white"
                            style={{ width: "50%" }}
                            onClick={() => {
                              setAppleLoading(true);
                              setVisaButtonLoading(false);
                              setMadaButtonLoading(false);
                              setPayApple(!payApple);
                              setAppleTimer();
                              setPayCard(false);
                              setPayCardMada(false);
                            }}
                          >
                            {!appleLoading && (
                              <span>
                                {getTranslation("pay_by_apple", isRTL)}
                              </span>
                            )}
                            {appleLoading && (
                              <span
                                className="indicator-progress"
                                style={{ display: "block" }}
                              >
                                {getTranslation("please_wait", isRTL)}...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                              </span>
                            )}
                          </button>
                        )}

                        {payApple &&
                          renderPaymentForm(
                            checkoutId3,
                            Constants.APPLE,
                            "APPLEPAY"
                          )}
                      </div>
                      <br />
                    </>
                  )}
                  <div className="overflow-hidden bg-white shadow rounded-lg pt-4 pb-4 pl-2 pr-2">
                    <div style={{ marginRight: "50%", width: "45%" }}>
                      <img
                        src={toAbsoluteUrl("/media/image/visa.png")}
                        alt=""
                        className="mw-100 mb-2 p-2"
                        style={{ display: "inline-block" }}
                      />
                      <img
                        src={toAbsoluteUrl("/media/image/masterCard.png")}
                        alt=""
                        className="mw-100 mb-2 p-2"
                        style={{ display: "inline-block" }}
                      />
                    </div>

                    <button
                      className="py-2 bg-black rounded text-white"
                      style={{ width: "50%" }}
                      onClick={() => {
                        setVisaButtonLoading(true);
                        setMadaButtonLoading(false);
                        setAppleLoading(false);
                        setDefaultButtonloading(true);
                        setPayCard(!payCard);
                        setPayCardMada(false);
                        setPayApple(false);
                      }}
                    >
                      {!visaButtonLoading && (
                        <span>{getTranslation("pay_by_card", isRTL)}</span>
                      )}
                      {visaButtonLoading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          {getTranslation("please_wait", isRTL)}...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                    <div className="p-2 fw-bold text-muted text-sm">
                      <p>* {getTranslation("visa_master", isRTL)}</p>
                    </div>

                    {payCard &&
                      renderPaymentForm(
                        checkoutId1,
                        Constants.VISA,
                        "VISA MASTER AMEX"
                      )}

                    {/* {state.checkoutId != null && ( */}
                    {/* <form
                      action={link}
                      className="paymentWidgets"
                      data-brands="VISA MASTER AMEX"
                    ></form> */}
                    {/* )} */}
                  </div>
                  <br />
                  <div className="overflow-hidden bg-white shadow rounded-lg pt-4 pb-4 pl-2 pr-2">
                    <div style={{ marginRight: "50%", width: "30%" }}>
                      <img
                        src={toAbsoluteUrl("/media/image/mada.png")}
                        alt=""
                        className="mw-100 mb-2"
                        style={{ display: "inline-block" }}
                      />
                    </div>

                    <button
                      className="py-2 bg-black rounded text-white"
                      style={{ width: "50%" }}
                      onClick={() => {
                        setMadaButtonLoading(true);
                        setAppleLoading(false);
                        setVisaButtonLoading(false);
                        setDefaultButtonloading(true);
                        setPayCardMada(!payCardMada);
                        setPayCard(false);
                        setPayApple(false);
                      }}
                    >
                      {!madaButtonLoading && (
                        <span>{getTranslation("pay_by_card", isRTL)}</span>
                      )}
                      {madaButtonLoading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          {getTranslation("please_wait", isRTL)}...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                    <div className="p-2 fw-bold text-muted text-sm">
                      <p>* {getTranslation("mada_card", isRTL)}</p>
                    </div>
                    {payCardMada &&
                      renderPaymentForm(checkoutId2, Constants.MADA, "MADA")}
                    {/* {state.checkoutId != null && ( */}
                    {/* <form
                      action={link}
                      className="paymentWidgets"
                      data-brands="MADA"
                    ></form> */}
                    {/* )} */}
                  </div>
                  <br />
                  {/* <div className="items-center overflow-hidden bg-white shadow rounded-lg pt-2 pb-2">
                    <button style={{ marginRight: "40%", width: "60%" }}>
                      <span>Apple Pay</span>
                    </button>
                  </div> */}

                  <br />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div style={{ height: "950px", padding: "100px" }}>
          <div className="d-flex flex-center flex-column flex-column-fluid p-10 items-center ">
            {/* begin::Logo */}
            <span className="mb-5"></span>
          </div>
          <Loading />
        </div>
      )}
    </div>
  );
}

// code for reference
// const form = document.createElement("form");
// const form1 = document.createElement("form")
// const form2= document.createElement("form")
// let link = `${Config.FRONTEND_LOCAL_URL}payment/result/${orderId}`
// form.action = link;
// form.setAttribute("class", `paymentWidgets`);
// form.setAttribute("data-brands", "VISA MASTER AMEX");
// document.body.appendChild(form);
// setState({
//   checkoutId1: checkoutId1,
//   checkoutId2: checkoutId2,
//   loading: false,
// });
// form1.action = "http://google.com";
// form1.setAttribute("class", "paymentWidgets");
// form1.setAttribute("data-brands", "GOOGLEPAY")
// form2.action = "http://google.com";
// form2.setAttribute("class", "paymentWidgets");
// form2.setAttribute("data-brands", "APPLEPAY")
// document.body.appendChild(form1);
// document.body.appendChild(form2);
