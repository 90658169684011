const config = {
  language: "english",
  topMenu: false,
  rtl: true,
  // AWS_CONFIG_FILE:'/path/to/config/file',
  API_LOCAL_URL: "http://localhost:7000/api/v1/",
  API_LOCAL_URL_WS: "ws://localhost:7000",
  FRONTEND_LOCAL_URL: "http://localhost:3000/",
  FRONTEND_DEV_URL: "https://devpay.tkrm.co/",
  FRONTEND_PROD_URL: "https://pay.tkrm.co/",
  API_KEY: "D80Rq0xJh3XcTFUpB4RhYOGoV56db9kl",
};

export default config;
