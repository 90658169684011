import styled, { css } from "styled-components";
interface BadgeProps {
  isRTL: boolean;
}

const Badge = styled.span<BadgeProps>`
  padding: 0.5rem 1rem;
  border-radius: 5px;
  margin: 0.25rem;
  background-color: #f5f8fa;
  cursor: pointer;
  font-weight: 600;
  color: #7e8299;
  ${(props) =>
    props.isRTL &&
    css`
      color: #009ef7;
    `}
`;
const LogoComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1.5rem; /* You can adjust the padding as needed */
`;
const Contanier = styled.div`
  maxwidth: 430px;
  height: 950px;
  padding: 60px;
`;

const SelectComponent = styled.select<BadgeProps>`
  border: 1px solid #f5f8fa;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  background-color: #f5f8fa;
  border-color: #f5f8fa;

  ${(props) =>
    props.isRTL
      ? css`
          margin-left: 10px;
        `
      : css`
          margin-right: 10px;
        `}
`;
export { Badge, LogoComponent, SelectComponent, Contanier };
