/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../helpers";
import { DataService } from "../../../../dataservice/dataService";
import * as Constants from "../../../../config/constants";
import { Loading } from "../../../../helpers/Loading";
import { setItem } from "../../../../utility/localstoragecontrol";
import FeedBackModal from "../../../../utility/components/FeedBackModal";
import { LogoComponent, Contanier } from "./OrderStyle";
import { getTranslation } from "../../../../utility/Translation";
import { useLayoutDirection } from "../../../../layout/LayoutDireactionContext";

interface State {
  referenceId: string;
  mobileNo: any;
}
export function OrderSumary() {
  const { isRTL } = useLayoutDirection();

  const [loading, setLoading] = useState(false);
  const [buttonLoading, setbuttonLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>("");
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [data, setData] = useState<Array<any>>([]);
  const location = useLocation();
  const state = location.state as State;
  const referenceId = state ? state.referenceId : null;
  const mobileNo = state ? state.mobileNo : null;

  const history = useNavigate();
  const time = new Date();
  // console.log("reference", referenceId);
  // const handleIncrement = () => {
  //   // Increment count by 1, up to a maximum of 100
  //   if (count < 100) {
  //     setCount(count + 1);
  //   }
  // };

  // const handleDecrement = () => {
  //   // Decrement count by 1, down to a minimum of 1
  //   if (count > 1) {
  //     setCount(count - 1);
  //   }
  // };
  const closeError = () => {
    setError(false);
    if (
      errorMessage === getTranslation(Constants.REDIRECTERROR, true) ||
      errorMessage === getTranslation(Constants.REDIRECTERROR, false)
    ) {
      window.history.back();
    }
  };
  const onSubmit = () => {
    const fetchApiResponse = async () => {
      setbuttonLoading(true);
      await sendData();
    };
    fetchApiResponse();
  };
  const sendData = async () => {
    const endpoint = `payment/checkout?${Constants.API_KEY}`;
    let data = {
      referenceId: referenceId,
      mobileNo: mobileNo,
      created: Constants.ORDERCREATEDTRUE,
      usingCount: count,
    };
    await DataService.post(endpoint, data)
      .then((result: { data: any }) => {
        // console.log("Order summary", result);
        setItem("revisted", "no");
        setItem("payment", "sucess");
        let checkoutId1 = result.data.data.id;
        let checkoutId2 = result.data.mada.id;
        let checkoutId3 = result.data.apple.id;
        let orderId = result.data.orderId;
        history("/payment/checkout", {
          state: {
            referenceId: referenceId, //Group Id
            mobileNo: mobileNo,
            checkoutId1: checkoutId1,
            checkoutId2: checkoutId2,
            checkoutId3: checkoutId3,
            orderId: orderId,
          },
        });
        setbuttonLoading(false);
        // setState({
        //   checkoutId: result.data.data.id,
        //   loading: false
        // });
      })
      .catch((error: any) => {
        // console.log("error", error);
      });
  };
  const fetchData = async () => {
    setLoading(true);
    if (referenceId) {
      const endPoint = `group/${referenceId}/details?${Constants.API_KEY}`;

      await DataService.get(endPoint)
        .then((result: { data: any }) => {
          // console.log("result", result.data.data);
          setData(result.data.data);
          // history("/payment/summary", { state: { referenceId: referenceId } });
          setLoading(false);
        })
        .catch((error: any) => {
          setLoading(false);
          setError(true);
          setErrorMessage("The Restroom is not Active");
          setButtonDisabled(true);
        });
    } else {
      setLoading(false);
      setError(true);
      setErrorMessage(getTranslation("redirection_failed", isRTL));
      setButtonDisabled(true);
    }
  };
  useEffect(() => {
    const fetchApiResponse = async () => {
      await fetchData();
    };
    fetchApiResponse();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {error && (
        <FeedBackModal
          openModal={error}
          closeModal={closeError}
          text={errorMessage}
          subText={""}
          type={"danger"}
        />
      )}
      <Contanier>
        <LogoComponent>
          {/* begin::Logo */}
          <span className="mb-5">
            <img
              alt="Logo"
              src={toAbsoluteUrl("/media/logos/tkrm.svg")}
              className="theme-dark-show h-45px"
            />
          </span>
        </LogoComponent>
        {loading === false ? (
          <>
            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="font-bold text-xl mb-2">
                  {getTranslation("order_details", isRTL)}
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  {getTranslation("please_check_details", isRTL)}
                </p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-gray-50 px-3 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium ">
                      <div>{getTranslation("organization", isRTL)}</div>
                      <div>{getTranslation("name", isRTL)}</div>
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data[0]?.organizationNameEn}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-3 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium ">
                      {getTranslation("branch", isRTL)}

                      {getTranslation("name", isRTL)}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data[0]?.branchNameEn}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-3 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium ">
                      {getTranslation("amount", isRTL)}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {data[0]?.amount} {getTranslation("sar", isRTL)} + (VAT)
                    </dd>
                  </div>
                  {/* <div className="bg-gray-50 px-3 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium ">
                      {getTranslation("access", isRTL)}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <button
                        onClick={handleDecrement}
                        disabled={count <= 1}
                        className="bg-black text-white rounded-l w-8 h-8  items-center justify-center"
                      >
                        -
                      </button>
                      <span className="px-4 py-2 bg-gray-300">{count}</span>
                      <button
                        onClick={handleIncrement}
                        className="bg-black text-white rounded-r w-8 h-8  items-center justify-center"
                      >
                        +
                      </button>
                    </dd>
                  </div> */}
                  <div className="bg-gray-50 px-3 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium ">
                      {getTranslation("date_and_time", isRTL)}
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {time.toLocaleString()}
                    </dd>
                  </div>
                  <div
                    className="bg-gray-50 px-3 py-4  sm:px-6 "
                    style={{ textAlign: "center" }}
                  >
                    <button
                      type="submit"
                      id="kt_sign_in_submit"
                      onClick={onSubmit}
                      style={{ width: "100%" }}
                      className="bg-black rounded text-white p-3"
                      disabled={buttonDisabled}
                    >
                      {!buttonLoading && (
                        <span>
                          {buttonDisabled ? (
                            <span style={{ color: "rgba(255, 255, 255, 0.4)" }}>
                              {getTranslation("checkout", isRTL)}
                            </span>
                          ) : (
                            getTranslation("checkout", isRTL)
                          )}
                        </span>
                      )}
                      {buttonLoading && (
                        <span
                          className="indicator-progress"
                          style={{ display: "block" }}
                        >
                          {getTranslation("please_wait", isRTL)}...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                </dl>
              </div>
            </div>
          </>
        ) : (
          <Loading />
        )}
      </Contanier>
    </>
  );
}
