import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { PaymentPage } from "../modules/payment/PaymentPage";
import { ChatBot } from "../helpers/ChatBot";

const { PUBLIC_URL } = process.env;
const AppRoutes: FC = () => {
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <ChatBot />
      <Routes>
        <Route>
          <Route path="error/*" element={<ErrorsPage />} />
          <Route path="payment/*" element={<PaymentPage />} />
          <Route path="*" element={<Navigate to="/payment/user" />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
