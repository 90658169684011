export const API_KEY = "API_KEY=D80Rq0xJh3XcTFUpB4RhYOGoV56db9kl";
export const VISA = "visa";
export const MADA = "mada";
export const APPLE = "apple";
export const DEV = "development";
export const PROD = "production";
export const REDIRECTERROR = "Redirection failed";
export const PAYMENTSTATUSTRUE = "payment_true";
export const PAYMENTSTATUSFALSE = "payment_false";
export const PAYMENT_SUCCESS = "payment_success";
export const PAYMENT_FAILED = "payment_failed";
export const ORDERCREATEDTRUE = "order_created_true";
export const ORDERCREATEDFALSE = "order_created_false";
