import ChatBotMod from "react-simple-chatbot";
import { FC } from "react";

const steps = [
  {
    id: "0",
    message: "Greetings from TKRM! How can I help you?",
    trigger: "1",
  },
  {
    id: "1",
    options: [
      { value: 1, label: "How do I complete my reservation?", trigger: "2" },
      { value: 2, label: "I am encountering a problem.", trigger: "3" },
    ],
  },
  {
    id: "2",
    message:
      "In order to complete your reservation you need to have a mobile number connected to a network. You will first need to enter your mobile number and then wait for an OTP to be received. Once the OTP has been received you will need to add that in as a security check. After that simply select your payment option and complete the payment to download the generated QR code.",
    trigger: "4",
  },
  {
    id: "3",
    message: "Can you elaborate?",
  },
  {
    id: "4",
    message: "Do you still need support?",
    trigger: "5",
  },
  {
    id: "5",
    options: [
      { value: 1, label: "No", trigger: "6" },
      { value: 2, label: "Yes", trigger: "1" },
    ],
  },
  {
    id: "6",
    message: "Have a good day!",
    end: true,
  },
];

export const ChatBot: FC = () => {
  return (
    <div>
      <ChatBotMod steps={steps} floating={true} headerTitle={"TKRM Support"} />
    </div>
  );
};
