import React from "react";
import styled from "styled-components";
import { KTSVG } from "../../helpers";

interface Props {
  openModal: boolean;
  closeModal: () => void;
  text: string;
  subText: string;
  type: string;
}

const ModalWrapper = styled.div<{ openModal: boolean }>`
  display: ${({ openModal }) => (openModal ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #fff;
  border: 1px solid grey;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  padding: 20px;
  max-width: 300px;
`;

const ModalHeader = styled.div`
  margin-top: 10%;
  margin: auto;
  width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const IconContainer = styled.div`
  padding: 20px;
`;

const Title = styled.h1`
  font-weight: 500;
  display: inline-block;
`;

const Subtitle = styled.p`
  color: #888;
`;

// const CloseButton = styled.button`
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   cursor: pointer;
//   background: none;
//   border: none;
//   font-size: 18px;
// `;
const FeedbackModal: React.FC<Props> = ({
  openModal,
  closeModal,
  text,
  subText,
  type,
}) => {
  const handleOverlayClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      closeModal();
    }
  };
  return (
    <>
      <ModalWrapper openModal={openModal} onClick={handleOverlayClick}>
        <ModalContent>
          {/* <CloseButton onClick={closeModal}>X</CloseButton> */}
          <ModalHeader>
            <IconContainer>
              {type === "success" ? (
                <KTSVG
                  path="/media/icons/duotune/general/gen026.svg"
                  className="text-green-500"
                />
              ) : type === "danger" ? (
                <KTSVG
                  path="/media/icons/duotune/arrows/arr015.svg"
                  className="text-red-500"
                />
              ) : (
                <KTSVG
                  path="/media/icons/duotune/abstract/abs019.svg"
                  className="text-yellow-500"
                />
              )}
            </IconContainer>
            <div>
              <Title>{text}</Title>
              <Subtitle>{subText}</Subtitle>
            </div>
          </ModalHeader>
        </ModalContent>
      </ModalWrapper>
    </>
  );
};

export default FeedbackModal;
